import styles from "./header.module.css";

export default function Header({ transaction }) {
    const title = transaction.merchant.name;
    const order = transaction.order_id || null;
    const id = transaction.id;

    return (
        <div className={styles.Header}>
            <h1>{title}</h1>

            {order && <h2>Заказ #{order}</h2>}
            {!order && <h2>Платеж #{id}</h2>}
        </div>
    );
}