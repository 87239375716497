import styles from "./container.module.css";

export default function Container({ children }) {
    return (
        <div className={styles.Payment}>
            <div className={styles.Container}>
                {children}
            </div>
        </div>
    );
}