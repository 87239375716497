import Container from "Components/Container/Container";
import Price from "Components/Price/Price";
import Header from "Components/Header/Header";
import Alert from "Components/Alert/Alert";
import buttonsStyles from "Components/Form/buttons.module.css";

export default function TransactionStatus ({ transaction }) {
  const { status, id } = transaction || {};
  return (
    <Container>
      {transaction && <Header transaction={transaction} />}
      {transaction && <Price transaction={transaction} />}
      <Status code={status} id={id} />
      {status && status !== 500 && <HelpMeButton url={transaction.return_url}/>}
      {status && status === 500 && <ReturnButton url={transaction.return_url}/>}
    </Container>
  );
}


const Status = ({ code }) => {
  if (!code) return <Alert title="⛔️ Ошибка платежа" message="Не можем найти ваш платеж. Возможно, вы ввели неправильный номер или такой платеж ещё не был зарегистрирован у нас." type="danger" />;
  switch (code) {
    case 500:
      return <Alert title="Оплачено 👍" message="Платеж успешно завершен" type="success" />;
    case 400:
      return <Alert title="⛔️ Ошибка платежа" message="Оплата не прошла. Пожалуйста, сообщите об этом нашей службе поддержки, чтобы выяснить причину" type="danger" />;
    default:
      return <Alert title="⚠️ Проблема с платежом" message={`Ваш платеж застрял и не подтверждается долгое время. Обратитесь к нашей службе поддержки для выяснения причины`} type="danger" />;
  }
};

// TODO: Если указан return_url, то уходим на него передавая туда в параметр id транзакции. 
// Иначе показываем свой сакцесс-компонент и ссылку на сайт мерчанта, чтобы покупатель мог на него «вернуться».
const ReturnButton = ({ url = "#" }) => (
  <div className={buttonsStyles.Buttons}>
    <a href={url} className={`${buttonsStyles.Button}`} type="success">Вернуться в магазин</a>
  </div>
);

// TODO: собираем данные транзакции, создаем в тикет для мерчанта и отправляем уведомление;
const HelpMeButton = ({ url = "#" }) => (
  <div className={buttonsStyles.Buttons}>
    <a href={url} className={`${buttonsStyles.Button}`} type="danger">Вернуться в магазин</a>
  </div>
);