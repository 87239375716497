import styles from "./price.module.css";
import { formatAmount } from "utils";

export default function Price ({ transaction }) {
    const commission = transaction.customer_commission_amount || 0;
    const amount = formatAmount(transaction.amount + commission);
    // const currency = payment.currency || "₸";
    const currency = transaction.currency_symbol || "?";

    return (
        // TODO: Добавить блок с описанием платежа справа от ценника
        <div className={styles.Price}>
            <span className={styles.Currency}>{currency}</span>
            <span className={styles.Amount}>{amount}</span>
        </div>
    );
}

