let _APP_CONFIG = {
    "API_URL": "https://api.test.ecom.emoney.tools",
    "SECURE_URL": "https://3ds.test.emoney.tools"
}

if (process.env.REACT_APP_WEBPACK_CONFIG) _APP_CONFIG = JSON.parse(process.env.REACT_APP_WEBPACK_CONFIG);

export const BASE_URL = _APP_CONFIG.API_URL;
export const SECURE_URL = _APP_CONFIG.SECURE_URL;

console.log(process.env);
// console.log("BASE_URL", BASE_URL);
// console.log("SECURE_URL", SECURE_URL);
